<template>
  <div class="plan-container">
    <add-account @add_account="createSuppAcc" />
    <download-bank-modal @download="download" />
    <supplier-docs-modal :supplier="currentSupplier" />
    <reconciliation-act-modal :supplier="supplier" />
    <div class="plan-header">
      <div></div>
      <e-select
        :value="[entity]"
        class="plan-select entities"
        placeholder="Все поставщики"
        :options="entity_options"
        search_in_drop
        selected_type="text"
        :dropdown_width="300"
        label="Организация"
        @change="(el) => setEntity(el[0])"
      >
        <template #custom_name="{ item }">
          <div class="entity w-100">
            <div class="name">
              {{ item?.name }}
            </div>
            <div class="inn_kpp">
              <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
              <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
            </div>
          </div>
        </template>
      </e-select>
      <e-select
        v-model="suppliers"
        class="plan-select entities"
        placeholder="Все поставщики"
        :options="suppliers_options"
        search_in_drop
        selected_type="text"
        :dropdown_width="300"
        multiple
        :max_selected_count="1"
        label="Поставщик"
      >
        <template #custom_name="{ item }">
          <div class="entity w-100">
            <p class="name">
              {{ item?.name }}
            </p>
            <div class="inn_kpp">
              <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
              <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
            </div>
          </div>
        </template>
      </e-select>
      <e-select
        v-model="account"
        key-name="check_acc"
        select_name="account_select"
        selected_type="text"
        position="bottom"
        class="plan-select account"
        :options="entity?.account"
        :serchable="true"
        search_in_drop
        :show_bottom_button="true"
        :dropdown_width="300"
        :disabled="!entity?.id"
        label="Счёт организации"
        @click_bottom="createAcc"
      >
        <template #custom_name="{ item }">
          <div class="dropdown-bank-info">
            <div>{{ item?.check_acc }}</div>
            <div class="bank-footer">
              <span class="bank-footer-first">Банк: {{ item.bank?.name }} </span>
              <span>БИК: {{ item?.bank?.bic }}</span>
            </div>
          </div>
        </template>
        <template #bottom_button_text>Счет организации</template>
      </e-select>
      <e-select
        v-model="status"
        class="plan-select status"
        placeholder="Все"
        :serchable="false"
        :options="status_options"
        :dropdown_width="200"
        label="Состояние"
        selected_type="text"
      />
    </div>
    <div class="mt-3 plan-table">
      <resizable-table
        style="flex: 1"
        :busy="false"
        table_name="fs"
        :default_fields="fields"
        :items="items"
        show_table_header_slot
      >
        <template #table_header>
          <div class="payment-table-header">
            <e-input
              v-model="pagination.search"
              class="payment-search-input"
            />
            <div class="payment-table-info">
              <span class="payment-table-text red">Выбрано к оплате: {{ formatMoney(to_pay) }} ₽</span>
              <span class="payment-table-text">Сумма на счёте: {{ formatMoney(account[0]?.balance) }} ₽</span>
              <e-button
                variant="primary"
                class="ml-auto"
                @click="createPaymentDocs"
              >
                Сформировать платёж: {{ formatMoney(payed) }} ₽
              </e-button>
            </div>
          </div>
        </template>
        <template #head_id="data">
          <e-checkbox
            :checked="checked(data.item?.supplier)"
            @click="selectItem(data.item?.supplier)"
          />
        </template>
        <template #body_id="data">
          <e-checkbox
            position="center"
            :checked="checked(data.item?.supplier)"
            @click="selectItem(data.item?.supplier)"
          />
        </template>
        <template #body_supplier="data">
          <entity-table-info :entity="data.item.supplier" />
        </template>
        <template #body_saldo="data">
          {{ formatMoney(data.value) }}
        </template>
        <template #body_to_pay="data">
          {{ formatMoney(data.value) }}
        </template>
        <template #body_payed="data">
          {{ formatMoney(data.value) }}
          <img
            class="cursor-pointer"
            src="/img/icons/payment/modal-open-icon.svg"
            @click="openSupplierDocModal(data.item?.supplier?.id)"
          />
        </template>
        <template #body_remains="data">
          {{ formatMoney(data.value) }}
        </template>
        <template #body_status="data">
          <pay-status :status="data.item.supplier?.pay_status" />
        </template>
      </resizable-table>
      <custom-navbar
        class="payment-table-navbar"
        :items="selected"
        items_name="payment-plan"
        @clear="clearSelected"
      >
        <template #buttons>
          <b-button
            variant="dark"
            @click="pay"
            >{{ need_pay ? 'Оплатить' : 'Снять оплату' }}</b-button
          >
          <b-button
            v-if="selected.length === 1"
            variant="dark"
            @click="changePaymentStatus"
          >
            {{ selected[0].pay_status === 'not_blocked' ? 'Заблокировать' : 'Снять блокировку' }}
          </b-button>
          <b-button
            v-if="selected.length === 1"
            variant="dark"
            @click="openReconciliationActModal"
            >Акт сверки
          </b-button>
        </template>
      </custom-navbar>
    </div>
    <pagination

      :total="total"
      style="margin-bottom: 0"
      table_name="payment-plan"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import EntityTableInfo from '@/components/EntityTableInfo.vue'
  import Pagination from '@/views/shared/components/table/Pagination.vue'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar.vue'
  import { mapActions, mapGetters } from 'vuex'
  import PayStatus from '@/views/payment/components/PayStatus.vue'
  import AddAccount from '@/views/core/modal/AddAccount.vue'
  import DownloadBankModal from '@/views/payment/components/DownloadBankModal.vue'
  import SupplierDocsModal from '@/views/payment/components/SupplierDocsModal.vue'
  import { formatMoney } from '@/utils/formatMoney'
  import ReconciliationActModal from '@/views/core/modal/ReconciliationActModal'

  export default {
    name: 'PaymentPlan',
    components: {
      SupplierDocsModal,
      DownloadBankModal,
      AddAccount,
      PayStatus,
      CustomNavbar,
      Pagination,
      EntityTableInfo,
      ReconciliationActModal
    },
    apollo: {
      Entities: {
        query: require('../gql/Entities.gql'),
        variables() {
          return {
            input: {
              select: 'ENTITY'
            }
          }
        },
        result({ data }) {
          this.entity_options = data.Entities
        }
      },
      Entity: {
        query: require('../gql/Entity.gql'),
        variables() {
          return {
            id: this.entity.id
          }
        },
        result({ data }) {
          this.setEntity(data.Entity)
        },
        skip() {
          return !this.entity?.id
        }
      },
      Suppliers: {
        query: require('../gql/getSuppliers.gql'),
        result({ data }) {
          this.suppliers_options = data.Suppliers
        }
      },
      PaymentPlan: {
        debounce: 300,
        fetchPolicy: 'no-cache',
        query: require('../gql/PaymentPlan.graphql'),
        variables() {
          return {
            entity: this.entity.id,
            account: this.account[0]?.id,
            search: this.pagination.search,
            pay_status: this.status[0]?.id,
            pagination: {
              skip: this.pagination.skip,
              take: this.pagination.take,
              supplier: this.suppliers.map((el) => el.id)
            }
          }
        },
        result({ data }) {
          this.total = data.PaymentPlan?.total || 20
          this.items = data.PaymentPlan?.list || []
          this.payed = data.PaymentPlan?.payed || 0
          this.to_pay = data.PaymentPlan?.to_pay || 0
        },
        skip() {
          return !this.entity?.id
        }
      }
    },
    data() {
      return {
        account: [],
        status: [],
        entity_options: [],
        suppliers_options: [],
        suppliers: [],
        supplier: {},
        selected: [],
        pagination: { skip: 0, take: 20, search: '' },
        to_pay: 0,
        payed: 0,
        total: 0,
        currentSupplier: null,
        fields: [
          {
            key: 'id',
            label: '',
            checked: true,
            width: 50
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            width: 50
          },
          {
            key: 'supplier',
            label: 'Контрагент',
            checked: true,
            width: 270
          },
          {
            key: 'saldo',
            label: 'Сальдо, ₽',
            checked: true,
            is_number: true,
            width: 130
          },
          {
            key: 'to_pay',
            label: 'Заявлено, ₽',
            checked: true,
            is_number: true,
            width: 130
          },
          {
            key: 'payed',
            label: 'К оплате, ₽',
            checked: true,
            is_number: true,
            width: 130
          },
          {
            key: 'remains',
            label: 'Остаток',
            checked: true,
            is_number: true,
            width: 130
          },
          {
            key: 'status',
            label: 'Состояние',
            checked: true,
            width: 130
          }
        ],
        status_options: [
          { id: null, name: 'Все' },
          { id: 'blocked', name: 'Заблокирован' },
          { id: 'half_blocked', name: 'Частично заблокирован' },
          { id: 'not_blocked', name: 'К оплате' }
        ],
        items: [],
        paymentDocs: []
      }
    },
    computed: {
      ...mapGetters({
        entity: 'payment/getEntity'
      }),
      need_pay() {
        return this.selected
          .map((selected) => this.items?.find((el) => el?.supplier.id === selected?.id))
          .some((el) => !el?.payed)
      }
    },
    methods: {
      ...mapActions({
        setEntity: 'payment/setEntity'
      }),
      formatMoney(value) {
        return formatMoney(value, 2)
      },

      openReconciliationActModal() {
        this.supplier = this.selected[0]
        this.$bvModal.show('reconciliation-act-modal')
      },

      createAcc() {
        this.$bvModal.show('add-account-modal')
      },
      async createSuppAcc(account) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../contractors/gql/createAccount.gql'),
          variables: {
            input: { ...account, entity: this.entity.id }
          }
        })
        this.account = [data.CreateCompanyBankAccount]
        this.$apollo.queries.Entity.refetch()
      },
      selectItem(val) {
        if (!this.selected.some((obj) => obj?.id === val?.id)) {
          this.selected.push(val)
        } else {
          this.selected = this.selected.filter((obj) => obj?.id !== val?.id)
        }
      },
      checked(val) {
        return !!this.selected.find((obj) => obj?.id === val?.id)
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },
      clearSelected() {
        this.selected = []
      },
      async pay() {
        await this.$apollo.mutate({
          mutation: require('../gql/MakePayment.graphql'),
          variables: {
            input: {
              entity: this.entity.id,
              suppliers: this.selected.map((el) => el.id),
              sum: this.need_pay ? 1000 : 0
            }
          }
        })
        await this.$apollo.queries.PaymentPlan.refetch()
      },
      async changePaymentStatus() {
        await this.$apollo.mutate({
          mutation: require('../gql/ChangeEntityPayStatus.graphql'),
          variables: {
            pay_status: this.selected[0].pay_status === 'not_blocked' ? 'blocked' : 'not_blocked',
            id: this.selected[0].id
          }
        })
        this.selected = []
        await this.$apollo.queries.PaymentPlan.refetch()
      },
      async createPaymentDocs() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/CreatePaymentDocs.graphql'),
          variables: {
            input: {
              suppliers: this.selected.map((el) => el.id),
              entity: this.entity.id
            }
          }
        })
        this.paymentDocs = data.CreatePaymentDocs
        this.$bvModal.show('download-bank-modal')
        await this.$apollo.queries.PaymentPlan.refetch()
      },
      async download() {
        const { data } = await this.$apollo.query({
          query: require('../gql/ExportToBank.graphql'),
          variables: {
            ids: this.paymentDocs
          }
        })
        window.open(data.ExportToBank, '_blank')
      },
      openSupplierDocModal(id) {
        this.currentSupplier = id
        this.$bvModal.show('supplier-docs-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
  .dropdown-bank-info {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .bank-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    /* identical to box height, or 200% */

    /* Text/secondary - gray400 */

    color: #888888;
    .bank-footer-first {
      margin-right: 12px;
    }
  }
  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    .active-img {
      height: 12px;
      width: 12px;
    }
    p {
      margin-bottom: 0;
    }
    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      //color: #313131;
    }
    .inn_kpp {
      display: flex;
      .inn {
        margin-right: 5px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }
  .plan-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .plan-header {
    display: flex;
    flex-direction: row;
    .plan-select {
      margin-right: 16px;
      &.suppliers {
        width: 200px !important;
      }
      &.entities {
        width: 200px !important;
      }
      &.account {
        width: 170px !important;
      }
      &.status {
        width: 150px !important;
      }
    }
  }
  .plan-table {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    .payment-table-navbar {
      position: absolute;
      bottom: 10px;
      right: 10px;
      left: 10px;
    }
  }
  .payment-table-header {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .payment-search-input {
      max-width: 283px;
      margin-right: auto;
    }
    .payment-table-info {
      display: flex;
      align-items: center;
    }
    .payment-table-text {
      color: #888888;
      &.red {
        color: #e53835;
      }
      margin-right: 16px;
    }
  }
</style>
