<template>
  <div class="entity">
    <div class="name">
      {{ entity?.full_name || entity?.name || 'Не указан' }}
    </div>
    <div class="info">
      <div class="inn">ИНН: {{ entity.inn || '–' }}</div>
      <div>КПП:{{ entity.kpp || '–' }}</div>
    </div>
  </div>
</template>

<script>
  import { ProductModel } from '@/models/product.model'

  export default {
    name: 'EntityTableInfo',
    props: {
      entity: ProductModel
    },
    computed: {},
    methods: {}
  }
</script>

<style scoped lang="scss">
  .entity {
    width: 100%;
    text-align: start;
    .name {
      color: #313131;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
    .info {
      display: flex;
      color: var(--text-secondary-gray-400, #888);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 200% */
      .inn {
        margin-right: 8px;
      }
    }
  }
</style>
