<template>
  <div
    class="pay-status"
    :class="{ [status]: true }"
  >
    {{ text }}
  </div>
</template>
<script>
  export default {
    name: 'PayStatus',
    props: {
      status: String
    },
    computed: {
      text() {
        if (this.status === 'blocked') return 'Заблокирован'
        if (this.status === 'half_blocked') return 'Частично заблокирован'
        if (this.status === 'not_blocked') return 'К оплате'
        return 'К оплате'
      }
    }
  }
</script>
<style scoped lang="scss">
  .pay-status {
    padding: 8px 12px 8px 12px;
    border-radius: 3px;

    &.blocked {
      border: 1px #e53835 solid;
      color: #e53835;
    }
    &.half_blocked {
      border: 1px #ffa800 solid;
      color: #ffa800;
    }
    &.not_blocked {
      border: 1px #00cb91 solid;
      color: #00cb91;
    }
  }
</style>
