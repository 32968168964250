<template>
  <b-modal
    id="add-account-modal"
    size="lg"
    title="Новый счет"
    hide-footer
    centered
  >
    <template #modal-header="{ close }">
      <h5 class="mb-1">Новый счет</h5>
      <b-button
        class="close"
        @click="close()"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <b-row class="mt-2 b-addcontact">
      <b-col cols="4">
        <label>БИК</label>
        <b-form-input
          v-model="account.bank.bic"
          :class="{ error: isErrorBIC }"
          type="number"
          placeholder="БИК"
          @input="inputBik"
        />
        <div
          v-if="add_contact_error"
          class="mt-1 error"
        >
          * - Необходимо указать бик
        </div>
      </b-col>
      <b-col cols="4">
        <label>Корреспондетский счет</label>
        <b-form-input
          v-model="account.bank.correspondent_account"
          type="number"
          placeholder="Введите корр. счет"
        />
      </b-col>
      <b-col cols="4">
        <label>Рассчетный счет</label>
        <b-form-input
          v-model="account.check_acc"
          type="number"
          :class="{ error: isErrorCheckAcc }"
          placeholder="Введите рассчетный счет"
          @input="isErrorCheckAcc = false"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="4">
        <label>Банк</label>
        <b-form-input
          v-model="account.bank.name"
          type="text"
          placeholder="Введите банк"
        />
      </b-col>
      <b-col cols="8">
        <label>Комментарий</label>
        <b-form-input
          v-model="account.comment"
          type="text"
          placeholder="Введите комментарий"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3 mb-4">
      <b-col>
        <b-button
          variant="primary"
          @click="add_account"
        >
          Добавить счет
        </b-button>
        <b-button
          class="ml-2"
          variant="outline-primary"
          @click="toggleForm('contact')"
        >
          Отменить
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
  export default {
    name: 'AddAccount',
    data() {
      return {
        isErrorBIC: false,
        isErrorCheckAcc: false,
        is_form_contact: false,
        account: {
          bank: {
            bic: '',
            name: '',
            correspondent_account: '',
            address_fact: null
          },
          comment: '',
          check_acc: ''
        },
        add_contact_error: false
      }
    },
    methods: {
      toggleForm() {
        this.$bvModal.hide('add-account-modal')
      },
      add_account() {
        if (!this.account?.check_acc && !this.account?.bank.bic) {
          this.$noty.error('Вы не добавили БИК банка и расчетный счет')
          this.isErrorBIC = true
          this.isErrorCheckAcc = true
          return
        }
        if (!this.account?.bank.bic) {
          this.$noty.error('Вы не добавили БИК банка')
          this.isErrorBIC = true
          return
        }
        if (!this.account?.check_acc) {
          this.isErrorCheckAcc = true
          this.$noty.error('Вы не добавили расчетный счет')
          return
        }
        this.isErrorBIC = false
        this.isErrorCheckAcc = false
        this.$emit('add_account', this.account)
        this.$bvModal.hide('add-account-modal')
      },
      async inputBik(bik) {
        this.isErrorBIC = false
        if (bik.length !== 9) return
        const { data } = await this.$apollo.query({
          query: require('../../contractors/gql/DadataBank.graphql'),
          variables: { bik: bik }
        })

        this.account.bank.bic = bik
        this.account.bank.correspondent_account = data.DadataBank?.correspondent_account
        this.account.bank.name = data.DadataBank?.name
        this.account.bank.address_fact = data.DadataBank?.address
      }
    }
  }
</script>

<style scoped lang="scss"></style>
