var render = function render(){
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pay-status",
    class: (_class = {}, _class[_vm.status] = true, _class)
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }