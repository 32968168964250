<template>
  <div
    v-if="items.length > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear"
    />
    <span style="white-space: nowrap">{{ items.length }} {{ item_name }} </span>
    <div
      class="b-btns"
      style="margin-top: 0 !important"
    >
      <slot name="buttons">
        <b-button
          v-for="action of actions.filter((el) => (!el.length || el.length === items.length) && el.max >= items.length)"
          :key="action.id"
          variant="dark"
          @click="emitAction(action.id)"
        >
          <slot :name="'button_' + action.id"> {{ action.label }} </slot>
        </b-button>
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => []
      },
      actions: {
        type: Array,
        default: () => []
      },
      item_name: {
        type: String,
        default: 'Товаров'
      }
    },
    methods: {
      clear() {
        this.$emit('clear')
      },
      emitAction(action) {
        this.$emit(action)
      }
    }
  }
</script>
<style scoped lang="scss">
  ::v-deep() {
    .b-btns > .btn {
      font-size: 16px;
    }
  }
</style>
