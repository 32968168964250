var render = function render(){
  var _vm$entity, _vm$entity2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "entity"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(((_vm$entity = _vm.entity) === null || _vm$entity === void 0 ? void 0 : _vm$entity.full_name) || ((_vm$entity2 = _vm.entity) === null || _vm$entity2 === void 0 ? void 0 : _vm$entity2.name) || 'Не указан') + " ")]), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "inn"
  }, [_vm._v("ИНН: " + _vm._s(_vm.entity.inn || '–'))]), _c('div', [_vm._v("КПП:" + _vm._s(_vm.entity.kpp || '–'))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }