<template>
  <div>
    <b-modal
      id="download-bank-modal"
      hide-footer
      hide-header
      centered
    >
      <h5>Платёж успешно сформирован</h5>
      Хотите скачать файл для загрузки данных в банк?
      <div class="mt-3 d-flex mb-3">
        <e-button
          variant="primary"
          class="mr-2"
          @click="close"
          >Продолжить без скачивания</e-button
        >
        <e-button
          variant="outline-primary"
          @click="download"
        >
          Скачать
        </e-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
  export default {
    name: 'DownloadBankModal',
    methods: {
      download() {
        this.$emit('download')
        this.$bvModal.hide('download-bank-modal')
      },
      close() {
        this.$bvModal.hide('download-bank-modal')
      }
    }
  }
</script>

<style scoped lang="scss"></style>
