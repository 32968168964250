var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length > 0 ? _c('div', {
    staticClass: "b-table__navbar"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/table/icon_checked.svg",
      "alt": ""
    },
    on: {
      "click": _vm.clear
    }
  }), _c('span', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(_vm._s(_vm.items.length) + " " + _vm._s(_vm.item_name) + " ")]), _c('div', {
    staticClass: "b-btns",
    staticStyle: {
      "margin-top": "0 !important"
    }
  }, [_vm._t("buttons", function () {
    return _vm._l(_vm.actions.filter(function (el) {
      return (!el.length || el.length === _vm.items.length) && el.max >= _vm.items.length;
    }), function (action) {
      return _c('b-button', {
        key: action.id,
        attrs: {
          "variant": "dark"
        },
        on: {
          "click": function ($event) {
            return _vm.emitAction(action.id);
          }
        }
      }, [_vm._t('button_' + action.id, function () {
        return [_vm._v(" " + _vm._s(action.label) + " ")];
      })], 2);
    });
  })], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }