<template>
  <b-modal
    id="supplier-docs-modal"
    class="supplier-docs-modal"
    centered
    size="xl"
    hide-footer
    style="min-height: calc(100vh - 30rem)"
    scrollable
  >
    <template #modal-header="{ close }">
      <div class="mb-1 d-flex align-items-center">
        <div class="sup-docs-title">Список документов по поставщику</div>
        <div class="delimiter">
          <img
            src="/img/icons/delimiter.svg"
            alt=""
          />
        </div>
        <div class="d-flex align-items-center">от {{ date }}</div>
      </div>
      <b-button
        class="close"
        @click="close()"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <div
      style="height: 100%; overflow-y: auto"
      class="sup-modal-container"
    >
      <div class="payment-table-header">
        <e-input
          v-model="pagination.search"
          class="payment-search-input"
        />
        <div class="payment-table-info">
          <span class="payment-table-text red">К оплате: {{ formatMoney(to_pay) }} ₽</span>
          <span class="payment-table-text green">Оплачено: {{ formatMoney(payed) }} ₽</span>
        </div>
      </div>
      <resizable-table
        table_name="suppliers-docs"
        class="suppliers-docs"
        :default_fields="fields"
        :items="items"
        :busy="false"
        @row-click="openConfirm"
      >
        <template #head_id><e-checkbox /></template>
        <template #body_id="data">
          <e-checkbox
            :checked="checked(data.item)"
            @click="selectItem(data.item)"
        /></template>
        <template #body_type="data"> <ext-system-logo :ext_system="data.item.inc.edo" /></template>
        <template #body_sum="data">{{ formatMoney(data.item?.inc?.sum) }}</template>
        <template #body_need_sum="data">{{ formatMoney(data.value) }}</template>
        <template #body_payed_sum="data">{{ formatMoney(data.value) }}</template>
        <template #body_supplierDocument="data">
          <div class="b-date text-nowrap">{{ data.item.inc.supplierDocument?.id }}</div>
          <div class="b-date date mt-1 text-nowrap table-substr">
            {{ data.item.inc.supplierDocument?.date ? convertDate(data.item.inc.supplierDocument?.date) : '–' }}
          </div></template
        >
        <template #body_date="data">
          <div class="b-date">{{ data.item.inc?.number }}</div>
          <div class="b-date date mt-1 table-substr">
            {{ data.item.inc.date ? convertDate(data.item?.inc?.date) : '' }}
          </div>
        </template>
        <template #body_owner="data">
          <span class="d-flex float-left">
            {{ data.item?.inc?.owner?.last_name ?? '' }}
            {{ data.item?.inc?.owner?.first_name ?? '' }}
          </span>
        </template>
        <template #body_branch="data">
          {{ data.item?.inc?.branch?.name }}
        </template>
        <template #body_status="data">
          <pay-status :status="data.item.inc?.payment_blocked ? 'blocked' : 'not_blocked'" />
        </template>
        <template #body_operation> Приходный ордер </template>
      </resizable-table>
      <custom-navbar
        class="payment-table-navbar"
        :items="selected"
        items_name="payment-plan"
        @clear="clearSelected"
      >
        <template #buttons>
          <b-button
            variant="dark"
            @click="pay"
            >{{ payed_text }}</b-button
          >
          <b-button
            v-if="selected.length === 1"
            variant="dark"
            @click="blocked"
          >
            {{ selected[0].inc.payment_blocked ? 'Снять блокировку' : 'Заблокировать' }}
          </b-button>
          <b-button
            v-if="selected.length === 1"
            variant="dark"
            @click="payDateChange"
          >
            Изменить дату платежа
          </b-button>
        </template>
      </custom-navbar>
      <b-modal
        id="accept-doc-modal"
        centered
      >
        <template #modal-header>
          <h5>Подтверждение перехода к документу</h5>
        </template>
        Вы действительно хотите закрыть текущее окно и перейти к документу?
        <template #modal-footer="{ cancel }">
          <div class="d-flex w-75">
            <b-button
              variant="primary"
              class="mr-2"
              @click="openDocument"
            >
              Продолжить
            </b-button>
            <b-button
              variant="outline-primary"
              @click="cancel"
            >
              Отмена
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal
        id="change-doc-date-modal"
        centered
      >
        <template #modal-header>
          <h5>Изменение даты платежа</h5>
        </template>
        <div style="width: 200px">
          <date-picker
            id="date"
            v-model="new_date"
            name="date"
            :clearable="false"
            class="elm-calendar"
            :min-date="new Date()"
            :min-date-exact="new Date()"
            placeholder=""
            format="dd.MM.yyyy"
            transfer
            @on-change="setDate"
          />
        </div>

        <template #modal-footer="{ cancel }">
          <div class="d-flex w-75">
            <b-button
              variant="primary"
              class="mr-2"
              @click="changeDate"
            >
              Сохранить
            </b-button>
            <b-button
              variant="outline-primary"
              @click="cancel"
            >
              Отмена
            </b-button>
          </div>
        </template>
      </b-modal>
      <pagination
        :total="total"
        table_name="sup-modal-docs"
        @change_pagination="handlerPagination"
      />
    </div>
  </b-modal>
</template>
<script>
  import { formatMoney } from '@/utils/formatMoney'
  import { mapGetters } from 'vuex'
  import formatDate from '@/utils/formatDate'
  import ExtSystemLogo from '@/views/operational-processes/components/incoming/ExtSystemLogo.vue'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar.vue'
  import Pagination from '@/views/shared/components/table/Pagination.vue'
  import PayStatus from '@/views/payment/components/PayStatus.vue'

  export default {
    name: 'SupplierDocsModal',
    components: { PayStatus, Pagination, CustomNavbar, ExtSystemLogo },
    apollo: {
      DocsToPay: {
        fetchPolicy: 'no-cache',
        query: require('../gql/DocsToPay.graphql'),
        variables() {
          return {
            input: {
              entity: this.entity.id,
              supplier: this.supplier
            },
            pagination: {
              skip: this.pagination.skip,
              take: this.pagination.take
            }
          }
        },
        result({ data }) {
          this.items = data.DocsToPay.list || []
          this.total = data.DocsToPay.total || 0
          this.to_pay = data.DocsToPay.to_pay_total || 0
          this.payed = data.DocsToPay.payed_total || 0
        },
        skip() {
          return !this.supplier
        }
      }
    },
    props: {
      supplier: String
    },
    data() {
      return {
        month: {
          0: 'января',
          1: 'февраля',
          2: 'марта',
          3: 'апреля',
          4: 'мая',
          5: 'июня',
          6: 'июля',
          7: 'августа',
          8: 'сентября',
          9: 'октября',
          10: 'ноября',
          11: 'декабря'
        },
        items: [],
        selected: [],
        pagination: {
          skip: 0,
          take: 20
        },
        total: 0,
        currentDocument: null,
        fields: [
          {
            key: 'id',
            label: '',
            checked: true,
            width: 50
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            width: 40
          },
          {
            key: 'type',
            label: 'Тип',
            checked: true,
            width: 55
          },
          {
            key: 'date',
            label: '№ / Дата',
            checked: true,
            width: 70
          },
          {
            key: 'supplierDocument',
            label: '№ Внеш. / Дата',
            checked: true,
            width: 190
          },
          {
            key: 'sum',
            label: 'Сумма, ₽',
            checked: true,
            is_number: true,
            width: 140
          },
          {
            key: 'need_sum',
            label: 'Заявлено, ₽',
            checked: true,
            is_number: true,
            width: 140
          },
          {
            key: 'payed_sum',
            label: 'Оплачно, ₽',
            checked: true,
            is_number: true,
            width: 140
          },
          {
            key: 'operation',
            label: 'Операция',
            checked: true,
            width: 190
          },
          {
            key: 'status',
            label: 'Состояние',
            checked: true,
            width: 140
          },
          {
            key: 'owner',
            label: 'Менеджер',
            checked: true,
            width: 130
          },
          {
            key: 'branch',
            label: 'Магазин',
            checked: true,
            width: 270
          },
          {
            key: 'comment',
            label: 'Комментарий',
            checked: true,
            width: 240
          }
        ],
        payed: 0,
        to_pay: 0,
        new_date: new Date()
      }
    },
    computed: {
      ...mapGetters({
        entity: 'payment/getEntity'
      }),
      date() {
        const date = new Date()
        return date.getDate().toString().padStart(2, '0') + ' ' + this.month[date.getMonth()] + ' ' + date.getFullYear()
      },
      payed_text() {
        return this.selected.some((el) => !el.payed_sum) ? 'Оплатить' : 'Снять оплату'
      }
    },
    methods: {
      formatMoney(value) {
        return formatMoney(value, 2)
      },
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },
      clearSelected() {},
      selectItem(val) {
        if (!this.selected.some((obj) => obj?.id === val?.id)) {
          this.selected.push(val)
        } else {
          this.selected = this.selected.filter((obj) => obj?.id !== val?.id)
        }
      },
      checked(val) {
        return !!this.selected.find((obj) => obj?.id === val?.id)
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },
      async pay() {
        await this.$apollo.mutate({
          mutation: require('../gql/MakePayment.graphql'),
          variables: {
            input: {
              entity: this.entity.id,
              suppliers: this.supplier,
              docs: this.selected.map((el) => el.inc.id),
              sum: this.selected.some((el) => !el.payed_sum) ? 1 : 0
            }
          }
        })
        await this.$apollo.queries.DocsToPay.refetch()
        this.selected = []
      },
      async blocked() {
        await this.$apollo.mutate({
          mutation: require('../gql/ChangeIncomingPayStatus.graphql'),
          variables: {
            id: this.selected[0].inc.id,
            payment_blocked: !this.selected[0].inc.payment_blocked
          }
        })
        await this.$apollo.queries.DocsToPay.refetch()
        this.selected = []
      },
      openConfirm(row) {
        this.currentDocument = row.inc.id
        this.$bvModal.show('accept-doc-modal')
      },
      openDocument() {
        this.$router.push({
          name: 'operation-process.overhead.incoming.document.edit',
          params: { id: this.currentDocument }
        })
      },
      payDateChange() {
        this.$bvModal.show('change-doc-date-modal')
      },
      setDate(date) {
        console.log(new Date(date), date)
        // this.new_date = new Date(date)
      },
      async changeDate() {
        await this.$apollo.mutate({
          mutation: require('../gql/ChangePayDate.graphql'),
          variables: {
            input: {
              id: this.selected[0].id,
              due_date: this.new_date
            }
          }
        })
        this.$bvModal.hide('change-doc-date-modal')
        await this.$apollo.queries.DocsToPay.refetch()
        this.selected = []
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep {
    .modal-content {
      height: 80% !important;
    }
    //min-height: ;
  }
  .sup-docs-title {
    color: #313131;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .delimiter {
    margin-left: 8px;
    margin-right: 8px;
  }
  .suppliers-docs {
    padding-bottom: 22px;
  }
  .sup-modal-container {
    display: flex;
    flex-direction: column;
    position: relative;
    .payment-table-navbar {
      position: absolute;
      bottom: 40px;
      right: 10px;
      left: 10px;
    }
  }
  .table-substr {
    font-size: 12px;
    color: #888888;
  }
  .payment-table-header {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-top: 1px #e1e1e1 solid;
    border-right: 1px #e1e1e1 solid;
    border-left: 1px #e1e1e1 solid;
    border-radius: 2px;
    background: #fff;
    padding: 16px;
    .payment-search-input {
      max-width: 283px;
      margin-right: auto;
    }
    .payment-table-info {
      display: flex;
      align-items: center;
    }
    .payment-table-text {
      color: #888888;
      &.red {
        color: #e53835;
      }
      &.green {
        color: #00cb91;
      }
      margin-right: 16px;
    }
  }
</style>
