var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "download-bank-modal",
      "hide-footer": "",
      "hide-header": "",
      "centered": ""
    }
  }, [_c('h5', [_vm._v("Платёж успешно сформирован")]), _vm._v(" Хотите скачать файл для загрузки данных в банк? "), _c('div', {
    staticClass: "mt-3 d-flex mb-3"
  }, [_c('e-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Продолжить без скачивания")]), _c('e-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.download
    }
  }, [_vm._v(" Скачать ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }