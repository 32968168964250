var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "add-account-modal",
      "size": "lg",
      "title": "Новый счет",
      "hide-footer": "",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('h5', {
          staticClass: "mb-1"
        }, [_vm._v("Новый счет")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }])
  }, [_c('b-row', {
    staticClass: "mt-2 b-addcontact"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("БИК")]), _c('b-form-input', {
    class: {
      error: _vm.isErrorBIC
    },
    attrs: {
      "type": "number",
      "placeholder": "БИК"
    },
    on: {
      "input": _vm.inputBik
    },
    model: {
      value: _vm.account.bank.bic,
      callback: function ($$v) {
        _vm.$set(_vm.account.bank, "bic", $$v);
      },
      expression: "account.bank.bic"
    }
  }), _vm.add_contact_error ? _c('div', {
    staticClass: "mt-1 error"
  }, [_vm._v(" * - Необходимо указать бик ")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("Корреспондетский счет")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Введите корр. счет"
    },
    model: {
      value: _vm.account.bank.correspondent_account,
      callback: function ($$v) {
        _vm.$set(_vm.account.bank, "correspondent_account", $$v);
      },
      expression: "account.bank.correspondent_account"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("Рассчетный счет")]), _c('b-form-input', {
    class: {
      error: _vm.isErrorCheckAcc
    },
    attrs: {
      "type": "number",
      "placeholder": "Введите рассчетный счет"
    },
    on: {
      "input": function ($event) {
        _vm.isErrorCheckAcc = false;
      }
    },
    model: {
      value: _vm.account.check_acc,
      callback: function ($$v) {
        _vm.$set(_vm.account, "check_acc", $$v);
      },
      expression: "account.check_acc"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("Банк")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Введите банк"
    },
    model: {
      value: _vm.account.bank.name,
      callback: function ($$v) {
        _vm.$set(_vm.account.bank, "name", $$v);
      },
      expression: "account.bank.name"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('label', [_vm._v("Комментарий")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Введите комментарий"
    },
    model: {
      value: _vm.account.comment,
      callback: function ($$v) {
        _vm.$set(_vm.account, "comment", $$v);
      },
      expression: "account.comment"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-3 mb-4"
  }, [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.add_account
    }
  }, [_vm._v(" Добавить счет ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleForm('contact');
      }
    }
  }, [_vm._v(" Отменить ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }